.add-category {
	margin-top: 40px;

	&__plus {
		height: 33px;
		width: 33px;
		padding-right: 25px;
	}
	&:hover {
		background: white;
		border-radius: 20px;
		cursor: pointer;
		padding: 12px 60px;
		transition: 0.8s;
	}
	&:not(:hover) {
		transition: 0.8s;
	}

	&__popup {
		width: 335px;
		height: 170px;
		background: #d8ffee;
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
		border-radius: 30px;
		margin-top: 30px;
		padding-top: 35px;
		position: relative;
	}
}

.button {
	background: #ffffff;
	border-radius: 30px;
	color: #747474;
	border: 0;
	padding: 10px 70px;
	font-size: 17px;
	margin-top: 25px;

	&:hover {
		background: #f7d3f7;
		transition: 0.8s;
		cursor: pointer;
	}
	&:not(:hover) {
		transition: 0.8s;
	}
}

.close-popup {
	height: 25px;
	width: 25px;
	position: absolute;
	right: -4px;
	top: -4px;
	cursor: pointer;
}

input[type='text'] {
	font-size: 21px;
}
