* {
	margin: 0;
	padding: 0;
	list-style: none;
	outline: none;
	font-family: 'Comfortaa', cursive, -apple-system, system-ui, sans-serif;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.todo {
	display: flex;
	justify-content: space-around;
	text-align: center;

	&__category-tasks {
		width: 37%;
		background: rgb(218, 215, 215);
		font-size: 40px;
		padding-top: 20px;
	}

	&__list-left {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 30px;

		ul {
			margin-bottom: 40px;
		}
	}

	&__edit-tasks {
		background: rgb(247, 211, 247);
		font-size: 30px;
		padding: 30px 20px;
		flex: 1;
		overflow: auto;
	}

	&__list {
		height: 30px;
		width: 34px;
		padding-right: 25px;
	}
}

.active {
	background: white;
	border-radius: 20px;
	cursor: pointer;
	padding: 12px 60px;
}

.field {
	background: #fff;
	border: 1px solid #efefef;
	box-sizing: border-box;
	border-radius: 30px;
	padding: 15px 30px;
	text-align: center;

	&:focus {
		border: 2px solid #f7d3f7;
	}

	&::placeholder {
		font-size: 18px;
	}
}

.line {
	border-left: 2.7px solid #d8feee;
	&:hover {
		cursor: col-resize;
	}
}
