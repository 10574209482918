.todo__list-right {
	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	width: 50%;

	h2 {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.todo__edit-image {
	opacity: 0.4;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}
}

.checkbox {
	input:checked + label {
		background-color: #d8feee;
		border-color: #d8feee;
	}

	&:hover {
		img {
			opacity: 1;
			cursor: pointer;
		}
	}

	label {
		border: 3px solid #b9b9b9;
		border-radius: 30px;
		margin-top: -1px;
		width: 25px;
		height: 25px;
		display: inline-block;
		cursor: pointer;
	}

	input {
		display: none;
	}

	position: relative;
	display: flex;
	align-items: flex-start;
}

.todo__items {
	display: flex;
	margin-left: 75px;
	margin-top: 50px;
	flex-direction: column;
}

.item-task {
	display: flex;
	margin-bottom: 30px;
	justify-content: flex-start;
}

.checkbox__done-item {
	width: 18px;
	height: 18px;
	position: absolute;
	top: 17px;
	right: 7px;
	opacity: 0;
}

.edit-item {
	margin-left: 30px;
}

.tasks__form-block {
	margin-top: 60px;
}

.add-task {
	height: 29px;
	width: 29px;
	margin-right: 25px;
}

.tasks__form-new {
	display: flex;
	align-items: center;
	margin-top: 35px;
	cursor: pointer;

	span {
		font-size: 33px;
	}
}

.button-task {
	background: #ffffff;
	border-radius: 30px;
	color: #747474;
	border: 0;
	padding: 10px 70px;
	font-size: 17px;
	margin-top: 35px;
	margin-right: 15px;

	&:hover {
		background: #d8feee;
		transition: 0.8s;
		cursor: pointer;
	}
	&:not(:hover) {
		transition: 0.8s;
	}
}

.field-width {
	width: 70%;
}

.common-style {
	height: 20px;
	width: 20px;
	cursor: pointer;

	&:hover {
		background-color: #d8feee;
		border-radius: 15px;
		padding: 10px 10px;
		transition: 0.8s;
	}

	&:not(:hover) {
		transition: 0.8s;
	}
}

.edit-style {
	margin-left: 50px;
	margin-right: 15px;
}
