.items {
	overflow: hidden;
	width: 93%;
}

.item {
	$self: &;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		text-overflow: ellipsis;
		overflow: hidden;
		width: 70%;
	}

	&:hover {
		background: white;
		border-radius: 20px;
		cursor: pointer;
		padding: 12px 60px;
		transition: 0.7s;
	}
	&:not(:hover) {
		transition: 0.7s;
	}
	&:hover #{$self}__delete {
		opacity: 0.4;
		transition: 0.7s;
	}

	#{$self}__delete {
		opacity: 0;

		&:hover {
			opacity: 1;
		}
	}
}

.item__delete {
	height: 18px;
	width: 18px;
	margin-left: 20px;
	margin-bottom: -2px;
}
